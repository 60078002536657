import React from 'react';

import Layout from '../layout/HomeLayout';
import ConsultantHomeView from '../views/consultant/home/consultantHomeView';
const HomePage = (): React.ReactElement => {
  return (
    <div>
      <Layout>
        <ConsultantHomeView />
      </Layout>
    </div>
  );
};

export default HomePage;
