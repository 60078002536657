import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { createStyles, Grid, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import ConsultantProjectView from './ConsultantProjectView';
import ConsultantViewHeader from './ConsultantViewHeader';

const styles = (): StyleRules =>
  createStyles({
    box: {
      padding: '2rem',
      margin: '1rem auto',
    },
    drawerContainer: {
      width: 500,
      display: 'flex',
      alignItems: 'center',
    },
  });

const ConsultantHomeView = ({ classes }: Props): ReactElement => {
  return (
    <>
      <Grid xs={12} className={classes.box} item container>
        <Helmet title="傅通自由职业"></Helmet>
        <ConsultantViewHeader />
        <ConsultantProjectView />
      </Grid>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(ConsultantHomeView);
